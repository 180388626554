import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import Slider from 'react-slick';
import axios from 'axios';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

function Category1() {
  const [sliderImages, setSliderImages] = useState([]);
  const [categoryDetails, setCategoryDetails] = useState({});
  const { id } = useParams();

  useEffect(() => {
    if (id) {
      axios.post(`https://newcph4v2.cph4.ch/api/Portfolio-category/details`, { id })
        .then(response => {
          setSliderImages(response.data.data.images); // Adjust based on your API response
          setCategoryDetails(response.data.data); // Adjust based on your API response
        })
        .catch(error => {
          console.error('Error fetching slider images:', error);
        });
    }
  }, [id]);

  // Example dynamic settings
  const [settings, setSettings] = useState({
    // dots: true,
    // infinite: true,
    // speed: 2000,
    // slidesToShow: 1,
    // slidesToScroll: 1,
    // autoplaySpeed: 2000,
    dots: true,
    infinite: sliderImages.length > 1 ? true :false,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: false,
    speed: 2000,
    autoplaySpeed: 5000,
    cssEase: "linear"
  });
  return (
    <>
      <h4 style={{ margin: "10px" }}>{categoryDetails.title}</h4>
      <Slider {...settings}>
        {sliderImages.map((image, index) => (
          <div key={index}>
            <img src={image.image} alt={`Slide ${index + 1}`} style={{ width: '100%', height: "90vh" }} />
          </div>
        ))}
      </Slider>
    </>
  );
}

export default Category1;
