import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes, Link } from 'react-router-dom';
import axios from 'axios'; // Import Axios
import Category1 from './Category1';
import Home from './Home';
import './App.css';

function App() {
  return (
    <Router>
      <div className="App">
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/details/:id" element={<Category1 />} />
          {/* Add more Routes as needed */}
        </Routes>
      </div>
    </Router>
  );
}

export default App;
