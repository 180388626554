import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes, Link } from 'react-router-dom';
import axios from 'axios'; // Import Axios
import Category1 from './Category1';
import './App.css';

function Home() {
    const [categories, setCategories] = useState([]);

    useEffect(() => {
        // Making an API call using Axios
        axios.post('https://newcph4v2.cph4.ch/api/Portfolio-category/portfolio-list', {})
            .then(response => {
                const formattedCategories = response.data.data.map((item, index) => ({
                    title: item.title,
                    name: item.portfolio_category.name,
                    image: 'https://newcph4v2.cph4.ch/' + item.portfolio_images[0]?.image, // Adjust according to your API's response structure
                    link: 'details/'+item.id
                }));
                setCategories(formattedCategories);
            })
            .catch(error => {
                console.error('Error fetching categories:', error);
            });
    }, []);

    return (
        <div className="App">
            <header className="App-header">
                <h1>Categories</h1>
                <div className="category-list">
                    {categories.map((category, index) => (
                        <div key={index} className="category-item">
                            <Link to={category.link}>
                                <img src={category.image} alt={category.name} />
                                <p>{category.name}</p>
                            </Link>
                        </div>
                    ))}
                </div>
            </header>
        </div>
    );
}

export default Home;
